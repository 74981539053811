import BaseApiService from './BaseApiService';
import log from '@/services/logConfig';

class MessagesService extends BaseApiService {
  constructor() {
    super();
    log.setLevel(process.env.VUE_APP_LOG_LEVEL || 'info');
  }
  
  async getUnreadCount() {
    try {
      log.debug('Fetching unread message count');
      const response = await this.api.get(`/messages/unreadCount`);
      return response.data;
    } catch (error) {
      log.error('Error fetching unread message count:', error);
      throw error;
    }
  }

  async fetch(page = 1) {
    try {
      log.debug('Fetching messages for page', page);
      const response = await this.api.get(`/messages?page=${page}`);
      return response.data;
    } catch (error) {
      log.error('Error fetching messages:', error);
      throw error;
    }
  }

  async markAsRead(messageId) {
    try {
      log.debug('Update message as read', messageId);
      const response = await this.api.post(`/messages/${messageId}/read`);
      return response.data;
    } catch (error) {
      log.error('Error fetching messages:', error);
      throw error;
    }
  }

  async send({ recipient, subject, message }) {
    try {
      log.debug('Sending message to', recipient);
      const response = await this.api.post('/messages', {
        recipient,
        subject,
        message,
      });
      return response.data;
    } catch (error) {
      log.error('Error sending message:', error);
      throw error;
    }
  }
}

export const messagesService = new MessagesService();
export const { fetch, markAsRead, send, getUnreadCount } = messagesService;