<template>
  <div :class="theme">
    <div class="app-container" :style="rootStyles">
      <LeftMenu :class="{ 'hidden': !showLeftMenu }" @update:expanded="handleMenuExpand" />
      <TopMenu v-if="showTopMenu" />
      <main class="content-area">
        <router-view></router-view>
        <BottomNavigation />
      </main>
      <BottomMenu :class="{ 'hidden': !showBottomMenu }" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import LeftMenu from './components/LeftMenu.vue';
import TopMenu from './components/TopMenu.vue';
import BottomNavigation from './components/BottomNavigation.vue';
import BottomMenu from './components/BottomMenu.vue';
import { useAuthStore } from '@/stores/auth';
import log from 'loglevel';
import { useTheme } from '@/composables/useTheme'

// Initialize theme handling
const { theme } = useTheme()

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const authStore = useAuthStore();
const isMenuExpanded = ref(false);
const windowWidth = ref(window.innerWidth);
const rootStyles = computed(() => ({
  '--is-authenticated': authStore.isAuthenticated ? '1' : '0',
  '--menu-width': (authStore.isAuthenticated && showLeftMenu.value) 
    ? (isMenuExpanded.value ? '200px' : '60px') 
    : '0px'
}));

const showLeftMenu = ref(window.innerWidth >= 768);

const handleResize = () => {
  showLeftMenu.value = window.innerWidth >= 768;
  windowWidth.value = window.innerWidth;
  log.debug('handleResize started to ', showLeftMenu.value);
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  
  // Add font preload links
  const fonts = [
    'https://fonts.googleapis.com/css?family=Montserrat:600,700,800,900&display=swap',
    'https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap'
  ];

  fonts.forEach(fontUrl => {
    const fontPreload = document.createElement('link');
    fontPreload.rel = 'preload';
    fontPreload.as = 'style';
    fontPreload.href = fontUrl;
    document.head.appendChild(fontPreload);
  });

  // Lazy load complete Bulma CSS
  const bulmaLink = document.createElement('link');
  bulmaLink.rel = 'stylesheet';
  bulmaLink.href = 'https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css';
  document.head.appendChild(bulmaLink);

  // Lazy load complete Font Awesome CSS
  const faLink = document.createElement('link');
  faLink.rel = 'stylesheet';
  faLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css';  // Update this path to your Font Awesome CSS
  document.head.appendChild(faLink);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const showTopMenu = computed(() => {
  if (!authStore.isAuthenticated)
    return true;
  if (authStore.isAuthenticated && windowWidth.value < 768)
    return true;
  return false;
});

const showBottomMenu = computed(() => {
  return authStore.isAuthenticated && windowWidth.value < 768;
});

const updateMenuWidth = (isExpanded) => {
    const width = isExpanded ? '240px' : '64px'; // adjust these values to match your menu widths
    document.documentElement.style.setProperty('--left-menu-width', width);
};

const handleMenuExpand = (expanded) => {
  isMenuExpanded.value = expanded;
  updateMenuWidth(expanded);
};
</script>

<style>
/* Critical styles */
@import '@/assets/styles/critical-bulma.css';
@import '@/assets/styles/critical-fontawesome.css';

/* Existing font imports */
@import url('https://fonts.googleapis.com/css?family=Montserrat:600,700,800,900&display=swap&font-display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap&font-display=swap');

h1 {
  padding: 0 !important;
}
</style>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-area {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem 1rem;
  transition: margin-left 0.3s;
}

.hidden {
  display: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .content-area {
    margin-bottom: calc(62px * var(--is-authenticated));
  }
}

@media screen and (min-width: 769px) {
  .content-area {
    padding: 0px 12px !important;
    margin-left: calc(var(--menu-width));
    width: calc(100% - var(--menu-width));
    transition: all 0.3s;
  }
}
</style>

<style>
:root {
  --menu-width: 60px;
}

/* Add this class to handle the expanded state */
:root.menu-expanded {
  --menu-width: 200px;
}
</style>