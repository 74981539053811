import { ref, watch } from 'vue'
import log from '@/services/logConfig';

export function useTheme() {
  const currentTheme = localStorage.getItem('theme')
  log.debug('Initial theme from localStorage:', currentTheme || 'not set')
  
  const theme = ref(currentTheme || 'light-mode')
  log.debug('Theme initialized as:', theme.value)

  watch(theme, (newTheme, oldTheme) => {
    log.debug('Theme changing:', { from: oldTheme, to: newTheme })
    document.body.className = newTheme
    
    // Debug CSS variables
    const styles = getComputedStyle(document.body)
    log.debug('Current CSS variables:', {
      color1: styles.getPropertyValue('--color1'),
      // Add other variables you want to check
    })
    
    localStorage.setItem('theme', newTheme)
  })

  // Debug initial CSS variables
  const initialStyles = getComputedStyle(document.body)
  log.debug('Initial CSS variables:', {
    color1: initialStyles.getPropertyValue('--color1'),
    // Add other variables you want to check
  })

  function toggleTheme() {
    const newTheme = theme.value === 'light' ? 'dark' : 'light'
    log.debug('Toggling theme to:', newTheme)
    theme.value = newTheme
  }

  return { theme, toggleTheme }
}