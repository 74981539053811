<template>
  <header class="top-menu" v-cloak>
    <div class="logo">
      <a href="/">
        <img src="@/assets/curalist-logo.svg" class="logo" alt="Curalist Logo">
        <img src="@/assets/curalist-name.svg" class="name" alt="Curalist">
      </a>
    </div>
    <nav class="nav-icons" v-if="isMenuReady">
      <ul>
        <li v-for="item in filteredMenuItems" :key="item.route">
          <router-link :to="item.route" class="nav-link">
            <div class="icon-wrapper">
              <FeatherIcon :icon="item.icon" :size="24" />
              <div v-if="(item.route === '/messages' && hasUnreadMessages) || (item.route === '/inbox' && hasUnreadInbox)" class="notification-badge">
                !
              </div>
            </div>
            <div class="nav-label" if-v="item.label">{{ item.label }}</div>
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import FeatherIcon from './FeatherIcon.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import log from '@/services/logConfig';
import { messagesService } from '@/services/api/MessagesService';

const authStore = useAuthStore();
const { isAuthenticated, roles } = storeToRefs(authStore);
const isMenuReady = ref(false);
const hasUnreadMessages = ref(true);
const hasUnreadInbox = ref(true);

log.debug('TopMenu: Component initialized', { 
    isAuthenticated: isAuthenticated.value,
    roles: roles.value
});

// Computed property for menu items
const menuItems = computed(() => {
    if (!isMenuReady.value) return [];

    const items = [
        // Only include login if not authenticated
        ...(!isAuthenticated.value ? [{ icon: 'log-in', label: 'Login', route: '/login', isAuthenticated: false }] : []),
        { icon: 'bell', route: '/messages', requiresAuth: true },
        //{ icon: 'inbox', route: '/inbox', requiresAuth: true, requiresAdmin: false },
        { icon: 'sliders', route: '/admin', requiresAuth: true, requiresAdmin: true },
        { icon: 'settings', route: '/settings', requiresAuth: true },
    ];

    log.debug('TopMenu: Menu items initialized', { 
        isAuthenticated: isAuthenticated.value,
        itemCount: items.length
    });

    return items;
});

// Computed property to filter menu items based on authentication status and roles
const filteredMenuItems = computed(() => {
    log.debug('TopMenu: Filtering menu items', { 
        isAuthenticated: isAuthenticated.value,
        roles: roles.value,
        totalItems: menuItems.value.length
    });

    const filtered = menuItems.value.filter(item => {
        // If the item requires authentication and the user is not authenticated
        if (item.requiresAuth && !isAuthenticated.value) {
            log.debug('TopMenu: Filtering out auth-required item', { item });
            return false;
        }

        if (item.isAuthenticated === false && isAuthenticated.value) {
            log.debug('TopMenu: Filtering out non-auth item for authenticated user', { item });
            return false;
        }

        // If the item requires an admin role and the user is not an admin
        if (item.requiresAdmin && !roles.value?.includes('admin')) {
            log.debug('TopMenu: Filtering out admin-required item', { item });
            return false;
        }

        return true;
    });

    log.debug('TopMenu: Menu items filtered', { 
        filteredCount: filtered.length,
        items: filtered
    });

    return filtered;
});

// Add the refresh function
const refreshMenu = async () => {
    log.debug('TopMenu: Refreshing menu state');
    try {
        await authStore.checkAuth();
        log.debug('TopMenu: Auth check completed during refresh', { 
            isAuthenticated: isAuthenticated.value,
            roles: roles.value
        });

        // Refresh unread message count if authenticated
        if (isAuthenticated.value) {
            const { unreadMessageCount } = await messagesService.getUnreadCount();
            hasUnreadMessages.value = unreadMessageCount > 0;
            log.debug('TopMenu: Unread message count refreshed', { unreadMessageCount });
        }
    } catch (error) {
        log.error('TopMenu: Error during menu refresh', { error });
    }
};

// Add event listeners when component is mounted
onMounted(async () => {
    log.debug('TopMenu: Component mounted, checking auth and unread messages');
    
    // Add event listener for auth state changes
    window.addEventListener('auth-state-changed', refreshMenu);
    
    // Initial setup
    try {
        await authStore.checkAuth();
        log.debug('TopMenu: Auth check completed', { 
            isAuthenticated: isAuthenticated.value,
            roles: roles.value
        });

        // Fetch unread message count
        const { unreadMessageCount } = await messagesService.getUnreadCount();
        hasUnreadMessages.value = unreadMessageCount > 0;
        log.debug('TopMenu: Unread message count fetched', { unreadMessageCount });
    } catch (error) {
        log.error('TopMenu: Error during initialization', { error });
    }
    
    isMenuReady.value = true;
    log.debug('TopMenu: Menu ready state set', { isMenuReady: isMenuReady.value });
});

// Clean up event listener when component is unmounted
onUnmounted(() => {
    window.removeEventListener('auth-state-changed', refreshMenu);
    log.debug('TopMenu: Component unmounted, removed event listener');
});

// Watchers come after all reactive references are defined
watch(() => isAuthenticated.value, (newValue) => {
    log.debug('TopMenu: Authentication state changed', { 
        isAuthenticated: newValue,
        roles: roles.value
    });
});

watch(() => hasUnreadMessages.value, (newValue) => {
    log.debug('TopMenu: Unread messages state changed', { hasUnread: newValue });
});

watch(() => hasUnreadInbox.value, (newValue) => {
    log.debug('TopMenu: Unread inbox state changed', { hasUnread: newValue });
});
</script>

<style scoped>

[v-cloak] {
  display: none;
}

.top-menu {
  height: 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--menu-color);/* Brighter and livelier yellow background */
}

.top-menu .logo {
  height: 24px;
  display: flex;
  align-items: center;
}

.top-menu .logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 24px; /* Match the logo height */
}

.top-menu .logo img {
  display: block; /* Prevent inline image spacing issues */
  height: 24px;
  width: auto;
}

.top-menu .name {
  margin-left: 8px;
  color: #333333; /* Dark gray for clear visibility */
  transition: opacity 0.2s ease-out;
}

.nav-icons {
  margin-right: 0;
}

.nav-icons ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-icons li {
  margin-right: 1rem;
}

.nav-icons li:last-child {
  margin-right: 0; /* Remove margin from the last icon */
}

.nav-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px; /* Adjust size as needed */
  color: #333333; /* Dark gray for icons */
  text-decoration: none;
  border-radius: 50%; /* Circular hover effect */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-icons i {
  font-size: 1.2rem;
}

.nav-icons .nav-label {
  white-space: nowrap;
  margin-left: 0.2em;
  color: #333333; /* Dark gray text for labels */
  font-size: 12px; /* Slightly smaller label text */
}

.hidden-on-desktop {
  display: none;
}

.icon-wrapper {
  position: relative;
  display: inline-flex;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #FF4040; /* Bright red for notifications */
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for emphasis */
}

/* Hide the name on small screens */
@media (max-width: 360px) {
  .top-menu .name {
    opacity: 0;
    width: 0;
    margin-left: 0;
    overflow: hidden;
  }
}


</style>