// Framework imports
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import log from './services/logConfig'

// Components
import App from './App.vue'
import FeatherIcon from './components/FeatherIcon.vue'

// Styles
import './assets/global.css'
import './assets/dynamic.css'
import './assets/darkTheme.css'

log.debug('App: Initialization started', {
  environment: process.env.NODE_ENV,
  version: process.env.VUE_APP_VERSION || 'unknown'
})

// Create the app instance
const app = createApp(App)

// Development configurations
if (process.env.NODE_ENV !== 'production') {
  app.config.performance = true
  app.config.devtools = true
  log.debug('App: Development mode configurations enabled', {
    performance: true,
    devtools: true
  })
}

// Configure app
log.debug('App: Configuring application', {
  features: {
    pinia: true,
    router: true,
    components: ['FeatherIcon']
  }
})

app
  .component('FeatherIcon', FeatherIcon)
  .use(createPinia())
  .use(router)

// Mount app with error handling
try {
  log.debug('App: Attempting to mount application')
  app.mount('#app')
  log.debug('App: Application mounted successfully')
} catch (error) {
  log.error('App: Failed to mount application', {
    error: {
      message: error.message,
      stack: error.stack,
      code: error.code
    }
  })
  console.error('Failed to mount app:', error)
}